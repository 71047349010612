<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import "./css/default.css";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: "Micholate";
  src: url("./assets/fonts/Micholate.otf");
  font-display: swap;
}

:root {
  /** No eliminar los 7 colores */
  --color-1: #7D1F1A;
  --color-2: #DC9129;
  --color-3: #ffffff;
  --color-4: #f5f5f5;
  --color-5: #a7a7a7;
  --color-6: #d9d9d9;
  --color-7: #ffffff;
  --opacity: rgba(255, 255, 255, 0.6);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: transparent;
}

.loaderView {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0vw;
  right: 0vw;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cargando{
  background-color: #7D1F1A;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5vw;
}
.cargando img{
   height: 50%;
}
.loaderSpinner{
  margin: auto;
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #7D1F1A;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

p.process{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--BLACK, #282828);
    font-family: "Libre Franklin";
    font-size: 1vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 1vw;
    text-transform: uppercase;
    letter-spacing: 2px;
}
    
@media (max-width: 834px) {
  .cargando{
    height:3vw;
  }
  p.process{
      font-size: 2vw;
      margin-top: 2vw;
      padding-bottom: 10vw;
  }
}
@media (max-width: 530px) {
  .cargando{
    height:5vw;
  }
  p.process{
      font-size: 4vw;
      margin-top: 5vw;
      padding-bottom: 10vw;
  }
}
.dflxc {
  display: flex;
  justify-content: center;
}

.dflx {
  display: flex;
}

.dflxa {
  display: flex;
  align-items: center;
}

.dflxca {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dflxsp {
  display: flex;
  justify-content: space-between;
}

.porel {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}

.box_ctr {
  display: flex;
  justify-content: center;
}

.box_ctr_alg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crs_ptr {
  cursor: pointer;
}

button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

picture {
  display: flex;
  width: fit-content;
  height: fit-content;
}

a {
  text-decoration: none;
}

dialog {
  min-width: 100%;
  min-height: 100%;
  background-color: transparent;
  border: 0;
}

dialog[open] {
    display: grid;
    place-items: center;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

#app {
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
  background-color: #F4F4F4;
  border-radius: 1.0416666666666665VW;
}

::-webkit-scrollbar-button:increment,
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

::-webkit-scrollbar-thumb {
  background-color: #7D1F1A;
  border-radius: 1.0416666666666665VW;
  border: 0.10416666666666667vw solid #7D1F1A;
}

::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 50000s ease-in-out 0s;
}



.cursorp {
  cursor: pointer;
}

p.err_msg {
  color: #7D1F1A !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.carg_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .7vw;


}

.carg_btns p:first-child {
  margin-right: 1vw;
}

.brdr {
  height: 2px;
  background-color: #ccc;
  width: 70.7vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
}

p.subttl {
  color: var(--color-4);
  font-weight: 600;
  font-size: 1.3020833333333335vw;
  font-family: 'DM Sans';
  margin: 0vw;
  margin-bottom: 1vw;
}

.carg_btns p {
  color: white;
  background-color: #7D1F1A;
  border-radius: 1.5vw;
  height: 2vw;
  margin: 0vw;
  letter-spacing: 3px;
  font-family: var(--DMSans);
  font-size: .7vw;
  width: 14vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carg_btns p img {
  width: 2.5vw;
}

.tbl_min {
  width: fit-content;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.mrtp {
  margin-top: 1vw !important;
}

.tbl_min_hdr {
  display: flex;
  align-items: center;
}

.tbl_min_hdr p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  color: #ffffff;
  font-family: 'DM Sans';
  background-color: var(--color-1);
  border-right: 1px solid white;
  margin: 0vw;
  padding: .3vw 0vw;
}

.tbl_min_row {
  display: flex;
  align-items: center;
  margin-top: .5vw;
}

.tbl_min_row p {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid white;
  font-size: .8vw;
  font-family: 'DM Sans';
  margin: 0vw;
}

p.tblm1 {
  width: 15vw;
}

p.tblm2 {
  width: 6vw;
}

p.tblm3 {
  width: 10vw;
}

p.tblm4 {
  width: 6vw;
}

p.tblm5 {
  width: 6vw;
}

p.tblm6 {
  width: 8vw;
}

p.tblm7 {
  width: 8vw;
}

p.tblm7 img {
  cursor: pointer;
  width: .8vw;
}

p.bg-grey {
  background-color: #242424;
}

fieldset {
  border: 0;
}

textarea {
  resize: none;
}
</style>
