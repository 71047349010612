import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import colors from './modules/colors'
import copy from './modules/copy'
import header from './modules/header'
import logo from './modules/logo'
import main from './modules/main'
import mainmtr from './modules/mainmtr'
import profile from './modules/profile'
import usuarios from './modules/usuarios'
import info from './modules/info'
import suscripciones from './modules/suscripciones'
import personalizacion from './modules/personalizacion'
import productos from './modules/productos'
import projectInfo from './modules/projectInfo'
import sociales from './modules/sociales'
import slides from './modules/slides'
import services from './modules/services'



Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    colors:colors,
    copy:copy,
    header:header,
    logo:logo,
    main: main,
    mainmtr:mainmtr,
    profile:profile,
    usuarios: usuarios,
    info: info,
    suscripciones: suscripciones,
    personalizacion,
    productos,
    projectInfo,
    sociales,
    slides,
    services,
  }
})
