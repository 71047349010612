import axios from "axios";


export default {

  login(data, headers) {
    return axios.post(`/login`, data, { headers });
  },

  resendCode(headers) {
    return axios.post(`/resend-code`, {}, { headers });
  },

  resendPass(data, headers) {
    return axios.post(`resend-pass/create`, data, { headers });
  },

  getInfoUser(data, headers) {
    return axios.post(`/data`, data, { headers });
  },

  getAllInfo(option, headers) {
    return axios.get(`/${option}`, { headers });
  },

  getStatusImage(payload, headers) {
    return axios.get(`/${payload.option}-img/${payload.image}`, { headers });
  },

  getInfoById(payload, headers) {
    return axios.get(`/${payload.option}/${payload.id}`, { headers });
  },

  getByDate(payload, headers) {
    return axios.get(`/${payload.option}/${payload.start}/${payload.end}`, { headers });
  },

  deleteItem(payload, headers) {
    return axios.delete(`/${payload.option}/${payload.id}`, {
      data: payload,
      headers: headers
    });
  },

  editItem(payload, headers) {
    return axios.put(`/${payload.option}`, payload.item, { headers });
  },

  addItem(payload, headers) {
    return axios.post(`/${payload.option}`, payload.item, { headers });
  },



}