import Vue from 'vue'
import VueRouter from 'vue-router'
import userService from '../services/user.service'
Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/aviso-de-privacidad',
    name: 'AvisoPrivacidad',
    component: () => import('../views/AvisoPrivacidad'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },

  {
    path: '/auth',
    name: 'autentificacion',
    component: () => import('../views/Autentificacion'),
    meta: {
      log: true,
      admin: true,
      auth: false
    }
  },

  {
    path: '/administrador',
    name: 'Admin',
    component: () => import('../views/adminWrapper'),
    meta: {
      log: true,
      admin: true,
      auth: true,
    }
  },

  

  {
    path: '*',
    name: '404',
    component: () => import('../views/Error404'),
    meta: {
      log: false,
      admin: false,

      auth: false
    }
  },


]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach(async (to, from, next) => {
  const meta_admin = to.matched.some(record => record.meta.admin);
  const meta_log = to.matched.some(record => record.meta.log);
  const meta_auth = to.matched.some(record => record.meta.auth);
  const tokenexp = await userService.tokenexp()
  let check = false
  let admin = false;
  let type = ''
  if (tokenexp) {
    localStorage.removeItem('tkn')
    next()
  }

  const response = await userService.getData()

  if (!response.success) {
    localStorage.removeItem('tkn')
  }

  if (response.success) {
    check = true;
    let isAdmin = userService.isAdmin(response.data);
    admin = isAdmin.admin;
    type = isAdmin.type;
  }

  const ath = await userService.checkAuth();

  if ((to.path === '/login' || to.path === '/login') && check) {
    localStorage.removeItem('tkn')
    next()
  }

  if (!meta_log) next();
  if (meta_log && !check){
     next('/login');
  }

  if (meta_admin) {
    if (admin) {
      if (meta_auth) {
        if (ath) {
          next();
        } else {
          localStorage.removeItem('tkn')
          next('/login')
        }
      } else {
        next()
      }
    } else {
      localStorage.removeItem('tkn')
      next('/')
    }
  } else {
    next()
  }


});




export default router

